.fasilitascont {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 45px;
  .fasilitascenter {
    width: 80%;
    object-fit: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 768px) {
      width: 100%;
    }
    .title {
      text-align: center;
      color: #3c3c3c;
      font-family: "Nunito", Sans-serif;
      font-weight: 700;
      font-size: 2rem;
      margin-bottom: 35px;
    }
    .card-image {
      object-fit: cover;
      width: 100%;
      height: 450px;
    }
    .slider {
      width: 90%;
      @media (max-width: 768px) {
        width: 100%;
      }
      .slick-next {
        right: 25px;
      }
      .slick-prev {
        z-index: 1;
        left: 25px;
      }
    }
    hr {
      margin-top: 50px;
      width: 100%;
    }
  }
}
