.studenthouse {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  flex-direction: column;
  margin-bottom: 20px;
  background-color: aliceblue;
  border-radius: 10px;
  .container-studenthouse {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    .header-studenthouse {
      width: 40%;
      order: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      @media (max-width: 768px) {
        width: 90%;
        margin-top: 30px;
      }
      .judul-studenthouse {
        width: 90%;
        font-size: 35px;
        font-family: "Poppins";
        font-weight: 600;
        line-height: 1;
        margin-bottom: 5px;
      }
      .desk-studenthouse {
        width: 90%;
        font-size: 14px;
        line-height: 1.5;
      }
    }
    .container-carousel {
      width: 60%;
      order: 1;
      @media (max-width: 768px) {
        width: 100%;
      }
      .carousel-fas {
        width: 100%;
        .slick-dots {
          text-align: center;
          width: 100%;
          bottom: 10px;
        }
        .slick-next::before,
        .slick-prev::before {
          font-family: "slick";
          font-size: 30px;
        }

        .slick-next {
          right: 25px;
        }
        .slick-prev {
          z-index: 1;
          left: 25px;
        }
        .slick-track {
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: center;
        }
        .image-logo {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: center;
          @media (max-width: 768px) {
            width: 100%;
          }
        }
      }
    }
  }
}
