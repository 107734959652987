.surcont {
  width: 100%;
  display: flex;
  justify-content: center;

  .surcenter {
    width: 60%;
    display: flex;
    align-items: center;
    flex-direction: column;
    @media (max-width: 768px) {
      width: 100%;
    }
    .surtitle {
      margin-top: 30px;
      margin-bottom: 30px;
      color: #3c3c3c;
      font-family: "Roboto", Sans-serif;
      font-weight: 600;
      font-size: 2.5rem;
      text-align: center;
    }
    .slider {
      width: 70%;
      @media (max-width: 768px) {
        width: 90%;
      }
      .slick-dots {
        bottom: 15px;
        li {
          margin: 0;
        }
      }
    }
  }
}
