.herocontainer {
  width: 100%;
  height: 100vh;
  @media (max-width: 768px) {
    height: 70vh;
  }
  .slider {
    width: 100%;
    height: 100%;
  }
}
