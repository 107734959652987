.promocont {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 25px;
  .promocenter {
    width: 60%;
    display: flex;
    margin-bottom: 25px;
    align-items: center;
    background-color: #125434;
    border-radius: 30px 30px 30px 30px;
    @media (max-width: 768px) {
      width: 90%;
      flex-direction: column;
    }
    .promocontent {
      width: 50%;
      padding: 15px;
      color: white;
      font-size: 12px;
      margin-left: 25px;
      @media (max-width: 768px) {
        width: 90%;
        order: 2;
      }
      .title {
        margin-bottom: 75px;
        text-align: center;
        color: #3c3c3c;
        font-family: "Roboto", Sans-serif;
        font-size: 2rem;
        font-weight: 600;
        color: white;
        @media (max-width: 768px) {
          margin-bottom: 25px;
        }
      }
      .promopoint {
        color: white;
        font-family: "Poppins", Sans-serif;
        font-size: 17px;
        font-weight: 400;
        line-height: 30px;
        word-spacing: 1px;
        .point {
          display: flex;
        }
      }
      .promobutton {
        margin-top: 15px;
        .wabutton {
          display: inline-block;
          line-height: 1;
          background-color: #61ce70;
          font-size: 15px;
          padding: 12px 24px;
          border-radius: 3px;
          color: #fff;
          fill: #fff;
          text-align: center;
          cursor: pointer;
          border: none;
        }
      }
    }
    .promoimg {
      width: 50%;
      padding: 15px;
      text-align: center;
      @media (max-width: 768px) {
        width: 100%;
        order: 1;
      }
      .img {
        width: 80%;
        @media (max-width: 768px) {
          width: 92%;
        }
      }
    }
  }
  hr {
    width: 60%;
  }
}
