.lokasicont {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 75px;
  .lokasicenter {
    width: 60%;
    @media (max-width: 768px) {
      width: 100%;
    }
    .lokasi {
      text-align: center;
      width: 100%;
      .map {
        @media (max-width: 768px) {
          width: 100%;
        }
      }
    }
    .lokasititle {
      color: #3c3c3c;
      font-family: "Roboto", sans-serif;
      font-weight: 600;
      font-size: 2rem;
    }
    .lokasipoint {
      .point {
        color: #7a7a7a;
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        padding: 5px;
      }
    }
  }
}
