.newcont {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 45px;
  .newcenter {
    width: 80%;
    display: flex;
    flex-direction: column;
    @media (max-width: 768px) {
      width: 100%;
    }
    .title {
      color: #3c3c3c;
      font-family: "Nunito", Sans-serif;
      font-size: 46px;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
      @media (max-width: 768px) {
        font-size: 35px;
      }
    }
    .subtitle {
      color: #3c3c3c;
      font-family: "Nunito", Sans-serif;
      font-size: 29px;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 30px;
    }
    .card-button {
      text-align: center;
      width: 100%;
      margin-bottom: 15px;
      margin-top: 20px;
      .button {
        font-size: 16px;
        font-weight: 400;
        line-height: 1em;
        letter-spacing: 0px;
        fill: #ffffff;
        color: #ffffff;
        background-color: #349b69;
        border-style: solid;
        border-width: 1px 1px 1px 1px;
        border-color: #73a38e;
        border-radius: 100px 100px 100px 100px;
        padding: 16px 85px;
        display: inline-block;
        cursor: pointer;
        transition: background-color 0.3s ease, color 0.3s ease;
      }
    }
    .nuananta {
      margin-top: 50px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .dekstop {
        width: 100%;
        border-radius: 15px;
        box-shadow: 0 0 10px 0;
        @media (max-width: 768px) {
          display: none;
        }
      }
      .mobile {
        display: none;
        @media (max-width: 768px) {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 80%;
          border-radius: 5px;
          box-shadow: 0 0 10px 0;
        }
      }
    }
    .banner {
      width: 100%;
      text-align: center;
      .bannerimg {
        width: 50%;
        @media (max-width: 768px) {
          width: 90%;
        }
        border-radius: 10px 10px 10px 10px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
      }
    }
    .cardsec {
      margin-top: 20px;
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 15px;
      @media (max-width: 768px) {
        align-items: center;
        flex-direction: column;
      }
    }
  }
}
