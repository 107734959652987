.aboutcont {
  width: 100%;
  display: flex;
  justify-content: center;
  .aboutcenter {
    width: 60%;
    @media (max-width: 768px) {
      width: 90%;
    }
    .aboutlogo {
      width: 100%;
      text-align: center;
      .logo {
        width: 25%;
        @media (max-width: 768px) {
          width: 100%;
        }
      }
    }
    .abouttitle {
      color: #3c3c3c;
      font-family: "Nunito", Sans-serif;
      font-weight: 800;
      font-size: 32px;
      text-align: center;
      margin-bottom: 15px;
    }
    .aboutdes {
      color: #3c3c3c;
      font-family: "Roboto", Sans-serif;
      font-size: 15px;
      font-weight: 400;
      line-height: 25px;
      letter-spacing: 0.4px;
      margin-bottom: 30px;
    }
  }
}
