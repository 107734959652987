@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");
.footercont {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .footercenter1 {
    width: 60%;
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
      flex-direction: column;
      width: 100%;
    }
    .left {
      width: 50%;
      text-align: center;
      @media (max-width: 768px) {
        order: 2;
        width: 100%;
      }
      .title {
        color: #919896;
        font-family: "Playfair Display", Sans-serif;
        font-weight: 700;
        letter-spacing: 3.8px;
        font-size: 2rem;
      }
      .subtitle {
        color: #349b69;
        font-family: "Verdana", Sans-serif;
        font-size: 42px;
        font-weight: 800;
        @media (max-width: 768px) {
          font-size: 35px;
        }
      }
      .alamat {
        color: #3c3c3c;
        font-family: "Verdana", Sans-serif;
        font-size: 14px;
        font-weight: 300;
      }
      .contact {
        color: #3c3c3c;
        font-family: "Verdana", Sans-serif;
        font-size: 14px;
        font-weight: 700;
      }
    }
    .right {
      @media (max-width: 768px) {
        order: 1;
        width: 100%;
        text-align: center;
      }
      width: 50%;
      .footerlogo {
        width: 80%;
      }
    }
  }
  .footerbottm {
    width: 100%;
    background-color: #349b69;
    height: 130px;
    text-align: center;
    color: white;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    hr {
      margin-top: 25px;
      width: 60%;
    }
  }
}
