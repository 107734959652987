.navbarcont {
  position: relative;
  height: 56px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #359a6c;
  font-family: "Poppins", Sans-serif;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 2.6px;
  z-index: 99;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  @media (max-width: 768px) {
    font-size: 17px;
  }
}
