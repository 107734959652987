.productcont {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  .productcenter {
    width: 80%;
    @media (max-width: 768px) {
      width: 100%;
    }
    .titleproduct {
      text-align: center;
      color: #3c3c3c;
      font-family: "Nunito", Sans-serif;
      font-size: 50px;
      font-weight: 700;
      margin-bottom: 30px;
    }
    .cardsection {
      width: 100%;
      display: grid;
      grid-template-columns: auto auto auto;
      justify-content: center;
      gap: 15px;
      @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
